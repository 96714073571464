import PropTypes from "prop-types";

export function NavigationButton({ text, onNavButtonClicked, disabled, buttonMode = "round", backgroundClasses }) {
  let classNames = `rounded-full ${backgroundClasses} p-3 text-white w-full sm:w-60 md:w-80 font-semibold text-xl mb-3 ${disabled ? "opacity-50" : ""}`;
  if (buttonMode === "square") {
    classNames = `focus:outline-dashed w-full ${backgroundClasses} text-white font-semibold text-2xl p-2 uppercase tracking-wider ${
      disabled ? "opacity-50" : ""
    }`;
  }
  if (buttonMode === "transparent-square") {
    classNames = `mx-auto mt-5 uppercase font-bold bg-transparent border-2 border-solid py-4 w-3/5 tracking-wider border-white 
    text-white ${disabled ? "opacity-50" : "hover:bg-white hover:text-grey-dark hover:border-grey-dark"}`;
  }
  if (buttonMode === "custom") {
    classNames = `${backgroundClasses} ${disabled ? "opacity-50" : ""}`;
  }

  return (
    <button onClick={onNavButtonClicked} disabled={disabled} className={`${classNames}`} tabIndex={0}>
      {text}
    </button>
  );
}

NavigationButton.propTypes = {
  text: PropTypes.string.isRequired,
  onNavButtonClicked: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  buttonMode: PropTypes.oneOf(["round", "square", "transparent-square", "custom"]),
  backgroundClasses: PropTypes.string,
  tabIndex: PropTypes.number,
};
