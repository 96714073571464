import { ButtonSelectionPropTypes } from "../../constants";
import { useStoreState } from "../../store";
import { Badge, SupText, TextEntry } from "./Shared";
import { getThemeStyles, getThemeClassString } from "../../themes";

export function AnswerButton({ currentStep, answer, isAnswerSelected, changeAnswer }) {
  const { settings } = useStoreState();
  const { formEmphasis, theme } = settings;
  const themeClasses = getThemeStyles(theme);
  const themeTextClasse = getThemeClassString(themeClasses, ["selectedTextColor"], "text-white");
  const selectedClasses =
    settings.buttonSelectedClasses ?? `bg-pink-electric outline outline-dashed outline-black outline-2 outline-offset-2 ${themeTextClasse}`;
  const regularClasses = formEmphasis ? "bg-white" : "bg-grey-light text-black";
  const outerButtonClasses = `${answer.buttonClasses ?? currentStep.answerButtonClasses ?? settings.answerButtonClasses ?? "rounded-3xl"} ${
    answer.additionalButtonClasses ?? ""
  }`;
  const innerButtonClasses =
    answer.innerButtonClasses ??
    currentStep.answerInnerButtonClasses ??
    settings.answerInnerButtonClasses ??
    "flex h-full min-h-fit w-auto min-w-36 cursor-pointer flex-col items-center px-3 py-3 xs:px-4";
  const iconClasses = answer.icon && (answer.iconClasses ?? currentStep.iconClasses ?? settings.iconClasses ?? "h-10 w-auto max-w-10 object-center");

  const textOnly = answer.color == null && answer.icon == null;

  let textClass =
    answer.classes ??
    currentStep.answerClasses ??
    `${textOnly ? "text-xs sm:text-base px-2 py-4 text-center font-semibold" : "text-xs sm:text-sm font-medium pt-1 xs:px-2"} text-center uppercase`;

  const selectKeys = [" ", "Enter"];

  const onKeyDown = (event) => {
    if (selectKeys.includes(event.key)) {
      changeAnswer(answer.value);
    }
  };
  const themeButtonClasses = getThemeClassString(themeClasses, ["buttonBgColor"], "bg-grey-light");
  const selectionClasses = isAnswerSelected(answer.value) ? selectedClasses : regularClasses;

  return (
    <div
      id={answer.value}
      className={`answer-button ${answer.icon ? "" : "justify-center"} ${outerButtonClasses} ${themeButtonClasses} ${selectionClasses} overflow-hidden`}
      onClick={() => changeAnswer(answer.value)}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      <div className={`answer-button__inner ${innerButtonClasses}`}>
        {answer.color && <p className="h-10 w-10 rounded-full pt-3" style={{ backgroundColor: answer.color }}></p>}
        {answer.icon && <img src={answer.icon} alt={`${answer.text} icon`} className={`answer-button__icon ${iconClasses}`} />}
        <div className={`answer-button__text ${textClass}`}>
          {answer.badge && <Badge {...answer.badge}></Badge>}
          {answer.text && <TextEntry text={answer.text} value={answer.value} />}
          {answer.supText && <SupText text={answer.supText} />}
        </div>
      </div>
    </div>
  );
}

AnswerButton.propTypes = ButtonSelectionPropTypes;
