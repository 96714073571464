// themes.js
const themes = {
  light: {
    textColor: "text-black",
    backgroundColor: "bg-grey-soft",
    headerTextColor: "text-black",
    headerBackground: "bg-grey-line",
    borderRadius: "rounded-6xl",
    indicatorBgColor: "bg-pink-electric",
    bottomBorderRadius: "rounded-b-6xl",
    paddingY: "py-4",
    paddingX: "px-4",
    topBorderRadius: "rounded-t-6xl",
    textCenter: "text-center",
    padding: "p-4",
    buttonBgColor: "bg-white",
    selectedTextColor: "text-pink-electric",
  },
  dark: {
    headerTextColor: "text-white",
    backgroundColor: "bg-grey-dark",
    headerBackground: "bg-black-laseraway",
    borderRadius: "rounded-6xl",
    textColor: "text-black",
    indicatorBgColor: "bg-pink-electric",
    bottomBorderRadius: "rounded-b-6xl",
    paddingY: "py-4",
    paddingX: "px-4",
    topBorderRadius: "rounded-t-6xl",
    textCenter: "text-center",
    padding: "p-4",
    buttonBgColor: "bg-grey-light",
    selectedTextColor: "text-white",
  },
  pink: {
    headerTextColor: "text-black",
    backgroundColor: "bg-pink-laseraway",
    headerBackground: "bg-pink-electric",
    borderRadius: "rounded-6xl",
    textColor: "text-black",
    indicatorBgColor: "bg-grey-light",
    bottomBorderRadius: "rounded-b-6xl",
    paddingY: "py-4",
    paddingX: "px-4",
    topBorderRadius: "rounded-t-6xl",
    textCenter: "text-center",
    padding: "p-4",
    buttonBgColor: "bg-grey-light",
    selectedTextColor: "text-white",
  },
};
export const getThemeStyles = (themeConfig) => {
  const baseStyles = themeConfig && themes[themeConfig.name] ? themes[themeConfig.name] : {};
  const customStyles = themeConfig && themeConfig.customStyles ? themeConfig.customStyles : {};
  return {
    ...baseStyles,
    ...customStyles,
  };
};

export function getThemeClassString(themeClasses, keys, defaultClass = "") {
  if (typeof themeClasses !== "object" || !Array.isArray(keys)) {
    return defaultClass;
  }
  const classString = keys
    .map((key) => themeClasses[key])
    .filter(Boolean)
    .join(" ");
  return classString || defaultClass;
}
