import PropTypes from "prop-types";
import FormStep from "./FormStep";
import { useStoreSelectors } from "../store";
import { useState } from 'react';
import CaretRight from "../assets/caret-right.svg";

export default function OtherOptionsGroup({ title = "Other Options" }) {
  const { selectCurrentStep } = useStoreSelectors();
  const currentStep = selectCurrentStep();
  const isOtherOptionsSelected = currentStep.answers.find((answer) => (answer.isOtherOption && answer.selected)) !== undefined;
  const [showOptions, setShowOptions] = useState(isOtherOptionsSelected);

  return (
    <div className={`bg-gray-50 box-border my-6 px-1 py-2 rounded-sm border-solid border-gray-100 border-[0.5px] w-full`}>
      <button
        className={`w-full border-none bg-transparent cursor-pointer font-bold text-xl flex items-center justify-between px-4 ${showOptions ? 'mb-2' : ''}`}
        onClick={() => setShowOptions(!showOptions)}
      >
        {title}
        <img className="w-8 transition-transform duration-300" style={{ transform: showOptions ? 'rotate(90deg)' : 'none' }} src={CaretRight} alt="See More" />
      </button>
      <div className={`duration-300 transition-all ${showOptions ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
        {showOptions && (
          <FormStep showOtherOptions={true} />
        )}
      </div>
    </div>
  );
}

OtherOptionsGroup.propTypes = {
  title: PropTypes.string,
};